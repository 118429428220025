@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Eczar-Regular';
    src: url('./assets/fonts/Eczar/static/Eczar-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Eczar-Bold';
    src: url('./assets/fonts/Eczar/static/Eczar-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'Lato-Regular';
    src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'UbuntuMono-Regular';
    src: url('./assets/fonts/Ubuntu_Mono/UbuntuMono-Regular.ttf') format('truetype');
}
  
@font-face {
    font-family: 'UbuntuMono-Bold';
    src: url('./assets/fonts/Ubuntu_Mono/UbuntuMono-Bold.ttf') format('truetype');
}
  
@layer components { 

    body {
        font-family: 'Lato-Regular', sans-serif;
        font-weight: 400;
        @apply text-white;
        @apply bg-slate-800;
    } 

    h1,h2,h3,h4,h5,h6 {
      font-family: 'Eczar-Bold', sans-serif;
      font-weight: 700; 
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    code {
      @apply bg-gray-800;
      @apply text-yellow-300;
      @apply font-code;
      @apply p-4;
      @apply rounded-lg;
      @apply text-xs;
    }

    .sticky-footer {
      @apply bg-gray-900;
      /* @apply fixed; */
      @apply inset-x-0;
      @apply bottom-0;
      @apply p-4;
    }

    .trustline-metric {
      @apply w-20 h-20 mr-2 p-2 text-white rounded-lg bg-slate-700 flex flex-col items-center justify-center;
    }

    .tooltip {
      position: relative;
      /* Add other styles as needed for your link */
    }
    
    .tooltip .tooltip-text {
      @apply absolute bg-gray-600 text-white text-sm px-2 py-1 rounded-md -mt-8 ml-2 opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out;
      /* Adjust positioning and other styles as needed for your tooltip */
    }
    
    .tooltip:hover .tooltip-text {
      @apply opacity-100;
    }

    .scrollable-overflow {
      overflow: auto; /* Enable scroll bar when content overflows */
    }


    .glow-yellow {
      text-shadow: rgb(34, 30, 13) 3px 0 20px; 
    }

    .div-pill {
      @apply uppercase rounded-full pl-4 pr-4 text-white font-bold text-center items-center justify-center flex flex-row;
    }

    .link-common {
      @apply text-yellow-300 hover:text-cyan-300 underline hover:cursor-pointer;
    }
    .div-link {
      @apply cursor-pointer hover:underline;
    }
    .btn-common-naked {
      @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer;
    }
    .btn-common {
      @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-slate-600 hover:bg-cyan-600 text-white hover:text-yellow-300 font-bold;
    }
    .btn-approve {
      @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-green-700 hover:text-white text-white hover:bg-green-500;
    }
    .btn-cancel {
      @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-slate-300 hover:bg-slate-700 hover:text-white text-black;
    }
    .link-align-center {
      @apply text-center justify-center items-center;
    }
    .alert-success {
      @apply bg-green-200 text-green-800 border-2 border-green-600 rounded-lg p-2;
    }
    .alert-danger {
      @apply bg-red-200 text-red-800 border-2 border-red-600 rounded-lg p-2;
    }
    .input-common {
      @apply bg-gray-200 text-slate-700 p-2 rounded-lg;
    }
}

.dropdown {
  position: relative;
}

.dropdown:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 8px;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
}

.dropdown:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 3px;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
}
  